<template>
  <div>
    <h3>{{info.contentTitle&&info.contentTitle[0]}}</h3>
    <span>分类：</span>
    <span>{{(info.teachingList||[]) |category('book') }}</span>    
    <span class="m-l-sm">{{(info.teachingList||[]) |category('material') }}</span>
    <span class="m-l-sm">{{(info.teachingList||[]) |category('unit') }}</span>
    <span class="p-l-md">年级：</span>
    <span>{{(info.teachingList||[]) |category('grade') }}</span>
    <span class="p-l-md">出版社：</span>
    <span>{{(info.teachingList||[]) |category('press') }}</span>
    <ProcessSteps v-if="!hiddeStep"
      style="margin: 6px 0;font-size: 16px;width: 700px;"
      :taskName="info.nowTaskName||info.delTaskName||info.taskName"
    />
  </div>
</template>

<script>
export default {
  props: {
    info: {},
    hiddeStep:{}
  },
  filters: {
    category(teachingList, type) {
      let press = teachingList.find(ele => ele.levelCode === type) || {};
      return press.name;
    }
  }
};
</script>

<style>
</style>